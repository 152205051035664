import "./SecondaryNav.css";
import { useLocation } from "react-router";
import { useEffect, useState } from "react";

export const SecondaryNav = ({ title }) => {
  const [pageTitle, setTitle] = useState(title ?? "");
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes("service")) {
      setTitle("Services");
    }
    if (location.pathname.includes("about")) {
        setTitle("Story behnd vision vidhya");
      }
  }, [location.pathname]);
  return (
    <header className="secondary-nav d-flex align-items-end px-5">
      <h1 className="text-white h3 fw-bold">{pageTitle}</h1>
    </header>
  );
};
